import React from 'react';

const VideoItem = ({ video, onVideoSelect }) => {
  const { title, description, thumbnails } = video.snippet;

  return (
    <div onClick={() => onVideoSelect(video)} className="card">
      <div className="card">
        <img alt={title} src={thumbnails.medium.url} style={{ width: '100%' }} />
      </div>
      <div className="content">
        <div className="header">
          {title}
        </div>
        <div className="description">
          {description}
        </div>
      </div>
    </div>
  );
}

export default VideoItem;
