import React from 'react';

const SearchBar = ({ onFormSubmit }) => {
  const [term, setTerm] = React.useState('')

  const onSubmit = event => {
    event.preventDefault();
    onFormSubmit(term);
  }

  return (
    <div className="ui segment">
      <form onSubmit={onSubmit}>
        <div className="ui fluid icon input">
          <input type="text" placeholder="Vido Search" value={term} onChange={e => setTerm(e.target.value)} />
          <i className="search icon"></i>
        </div>
      </form>
    </div>
  );
}

export default SearchBar;
