import React from 'react';
import VideoItem from './VideoItem';

const VideoList = ({ videos, onVideoSelect }) => {
  const renderdList = videos.map(
    video => <VideoItem video={video} key={video.id.videoId} onVideoSelect={onVideoSelect} />
  );

  return (
    <div className="ui cards one stackable">
      {renderdList}
    </div>
  );
}

export default VideoList;
