import React from 'react';
import SearchBar from './SearchBar';
import VideoList from './VideoList';
import VideoDetail from './VideoDetail';
import useVideos from '../hooks/useVideos';

const App = () => {
  const [selectedVideo, setSelectedVideo] = React.useState(null);
  const videoDecail = React.useRef();
  const { videos, search } = useVideos('React JS');

  React.useEffect(() => {
    setSelectedVideo(videos[0]);
  }, [videos]);

  const onVideoSelect = video => {
    setSelectedVideo(video);
    videoDecail.current.scrollIntoView();
  }

  return (
    <div className="ui container">
      <SearchBar onFormSubmit={search} />
      <div className="ui stackable grid">
        <div className="eleven wide column" ref={videoDecail}>
          <VideoDetail video={selectedVideo} />
        </div>
        <div className="five wide column">
          <VideoList videos={videos} onVideoSelect={onVideoSelect} />
        </div>

      </div>
    </div>
  );
}

export default App;
