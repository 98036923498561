import React from 'react';

const VideoDetail = ({ video }) => {
  if (!video)
    return <div>loading ..</div>

  const { title, description } = video.snippet;
  const videoSrc = `https://www.youtube.com/embed/${video.id.videoId}`

  return (
    <div className="ui segmant">
    <iframe frameBorder="0" src={videoSrc} title="video player" style={{ width: '100%', height: '450px' }}></iframe>
      <h2 className="ui header">{title}</h2>
      <p className="ui description">{description}</p>
    </div>
  );
}

export default VideoDetail;
